// src/pages/InvitationPage.js

import React, { useState } from "react";
import {
  Typography,
  Card,
  Form,
  Input,
  Button,
  message,
  Progress,
  Row,
  Col,
  Layout,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import useRegisterAgent from "../hooks/useRegisterAgent"; // Custom hook for registration logic

const { Title, Text } = Typography;

const { Content } = Layout;

const InvitationPage = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [redirectCountdown, setRedirectCountdown] = useState(3);
  const [isRedirecting, setIsRedirecting] = useState(false);

  // Extract token and username from the URL
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const username = queryParams.get("username");
  const role = queryParams.get("role");

  const { handleRegister, loading } = useRegisterAgent();

  // Form submission handler
  const onFinish = async (values) => {
    const { password } = values;

    // Start progress feedback
    setProgress(30);

    // Make the registration request
    const success = await handleRegister({ username, role, password, token });

    if (success) {
      message.success({
        content: "Registration successful! Redirecting to login...",
        duration: 5,
      });
      form.resetFields(); // Optionally reset form
      setIsRedirecting(true);

      // Start the redirection countdown with progress
      const interval = setInterval(() => {
        setRedirectCountdown((prev) => {
          if (prev === 1) {
            clearInterval(interval);
            navigate("/login"); // Redirect to login
          }
          return prev - 1;
        });
        setProgress((prev) => prev + 20);
      }, 1000);
    } else {
      message.error({
        content: "Registration failed. Please try again.",
        duration: 5,
      });
      setProgress(0); // Reset progress on failure
    }
  };

  return (
    <Layout>
      <Content
        style={{
          padding: "24px",
          background: "#f0f2f5",
          minHeight: "100vh",
          overflow: "auto",
        }}
      >
        <Card
          bordered={true}
          style={{
            maxWidth: "500px",
            margin: "auto",
            marginTop: "50px",
            padding: "20px",
            textAlign: "center",
          }}
        >
          <img
            src="/aroma-logo.png"
            alt="Next AI"
            style={{
              height: "80px",
              marginRight: "16px",
              marginTop: "7px",
              marginBottom: "0px",
            }}
          />

          {token && username && role ? (
            <>
              <Title level={2} style={{ marginBottom: "30px" }}>
                <span
                  style={{
                    color: "black",
                    marginTop: "5px",
                    fontStyle: "bold",
                    fontWeight: "400",
                    letterSpacing: "2px",
                    marginLeft: "0px",
                  }}
                >
                  Aroma
                </span>{" "}
                System Registration
              </Title>

              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{ username, token, role }}
                style={{ marginBottom: "20px" }}
              >
                {/* Username (Prepopulated and Disabled) */}
                <Form.Item label="Username" name="username">
                  <Input disabled />
                </Form.Item>

                {/* Token (Prepopulated and Disabled) */}
                <Form.Item label="Role" name="role">
                  <Input disabled />
                </Form.Item>

                {/* Password */}
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password placeholder="Enter your password" />
                </Form.Item>

                {/* Confirm Password */}
                <Form.Item
                  label="Confirm Password"
                  name="confirmPassword"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Passwords do not match!")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Confirm your password" />
                </Form.Item>

                {/* Submit Button */}
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    block
                  >
                    {loading ? "Submitting..." : "Register"}
                  </Button>
                </Form.Item>

                {/* Progress Bar */}
                {loading && (
                  <Progress percent={progress} size="small" showInfo={false} />
                )}

                {/* Redirection Countdown (Visible After Successful Registration) */}
                {isRedirecting && (
                  <Row
                    justify="center"
                    align="middle"
                    style={{ marginTop: "20px" }}
                  >
                    <Col>
                      <CheckCircleOutlined
                        style={{ color: "green", fontSize: "24px" }}
                      />
                      <Text style={{ marginLeft: "10px" }}>
                        Redirecting in {redirectCountdown} seconds...
                      </Text>
                    </Col>
                  </Row>
                )}
              </Form>
            </>
          ) : (
            <Row justify="center" align="middle" style={{ marginTop: "20px" }}>
              <Col>
                <ExclamationCircleOutlined
                  style={{ color: "red", fontSize: "48px" }}
                />
                <Title level={3} style={{ marginTop: "16px" }}>
                  Invalid Link
                </Title>
                <Text style={{ fontSize: "16px" }}>
                  Please contact the Supervisor.
                </Text>
              </Col>
            </Row>
          )}
        </Card>
      </Content>
    </Layout>
  );
};

export default InvitationPage;
