import React, { useState, useEffect } from "react";
import {
  Form,
  Card,
  Row,
  Col,
  Select,
  Table,
  Button,
  Spin,
  Input,
  Menu,
  Dropdown,
  message,
} from "antd";
import {
  SaveOutlined,
  PlusOutlined,
  MinusOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { API_URL, fetchJobDetails } from "../utils/jobApi";
import { getAccessToken } from "../utils/auth";
const OutputForm = ({
  form,
  handleSave,
  handleSchoolSearch,
  selectedSchool,
  setSelectedSchool,
  setSchoolOptions,
  setSearchValue,
  schoolOptions,
  searchValue,
  setFirstDropdownValue,
  secondDropdownOptions,
  setSecondDropdownOptions,
  optionsData,
  tableData,
  jobColumns,
  verifying,
  handleVerify,
  saving,
  handleSaving,
  handleAddRow,
  handleRemoveRow,
  jobId,
  // jobDetails,
}) => {
  console.log("tableData -> ",tableData );
  const [isVerifying, setIsVerifying] = useState(false);
  const [isAGTenant, setIsAGTenant] = useState(false);

  useEffect(() => {
    const tenantSlug = localStorage.getItem("tenant_slug");
    setIsAGTenant(tenantSlug === "ag");
  }, []);

  const columns = [
    ...jobColumns.map((column) => ({
      title: column,
      dataIndex: column,
      key: column,
      render: (text, record) => (
        <Form.Item
          name={[record.key, column]}
          style={{ margin: 0 }}
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
      ),
    })),
    {
      title: "Actions",
      key: "actions",
      render: (text, record, index) => (
        <div>
          <Button
            type="link"
            icon={<PlusOutlined />}
            onClick={() => handleAddRow(index, Object.keys(record))}
          />
          <Button
            type="link"
            icon={<MinusOutlined />}
            onClick={() => handleRemoveRow(index)}
            disabled={tableData.length === 1}
          />
        </div>
      ),
    },
  ];

  const handleFirstDropdownChange = (selectedValue) => {
    setFirstDropdownValue(selectedValue);
    const selectedScheme = optionsData.packageSchemes.find(
      (item) => item.name === selectedValue
    );
    const packages = selectedScheme ? selectedScheme.packages : [];
    setSecondDropdownOptions(Array.isArray(packages) ? packages : []);
  };

  const handleVerifyClick = async () => {
    setIsVerifying(true);
    try {
      await handleVerify();
    } finally {
      setIsVerifying(false);
    }
  };
  const downloadCSV = (data) => {
    const headers = Object.keys(data[0]);
    const rows = data.map((row) =>
      headers.map((field) => row[field]).join(",")
    );

    const csvContent = [headers.join(","), ...rows].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "formData.csv";
    link.click();
  };

  const handleDownload = async (index, type) => {
    try {
      console.log(jobId, index, type);

      const response = await fetch(
        `${API_URL}/jobs/${jobId}/download?format=${type}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      );

      if (response.ok) {
        console.log("downloading");

        const blob = await response.blob(); // Get the file as a blob

        let fileName;
        if (type === "json") {
          fileName = `job_${jobId}_output_${index}.json`;
        } else if (type === "xlsx") {
          fileName = `job_${jobId}_output_${index}.xlsx`;
        } else {
          console.error("Unsupported file type");
          return;
        }

        // Create a URL for the blob and trigger the file download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName; // Dynamically set the file name
        a.click();
        window.URL.revokeObjectURL(url); // Clean up the object URL after the download
        message.success("File downloaded successfully");
      } else {
        console.error("File download failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching the file:", error);
    }
  };

  // Menu for download format selection
  // Menu for download format selection
  const downloadMenu = (index) => (
    <Menu>
      <Menu.Item key="json" onClick={() => handleDownload(index, "json")}>
        JSON
      </Menu.Item>
      <Menu.Item key="xlsx" onClick={() => handleDownload(index, "xlsx")}>
        Excel
      </Menu.Item>
    </Menu>
  );

  return (
    <Form form={form} layout="vertical">
      {isAGTenant && (
        <Card
          style={{
            marginBottom: "24px",
            padding: "0px",
          }}
        >
          <Row gutter={[30, 30]}>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item
                name="schoolName"
                label="School Name"
                rules={[
                  {
                    required: isVerifying,
                    message: "Please select a school name!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a school"
                  onSearch={handleSchoolSearch}
                  onChange={(value) => {
                    setSelectedSchool(value);
                    form.setFieldsValue({ schoolName: value });
                    setSchoolOptions([]);
                    setSearchValue("");
                  }}
                  filterOption={false}
                  notFoundContent={null}
                  options={schoolOptions}
                  // defaultActiveFirstOption
                  showArrow={false}
                  value={selectedSchool}
                  allowClear
                  searchValue={searchValue}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
              <Form.Item
                name="scholarshipScheme"
                label="Scholarship Scheme"
                rules={[
                  {
                    required: isVerifying,
                    message: "Please select a scholarship scheme!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a scholarship scheme"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={handleFirstDropdownChange}
                >
                  {optionsData.scholarshipSchemes.map((scheme) => (
                    <Select.Option key={scheme} value={scheme}>
                      {scheme}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={13} md={6} lg={6}>
              <Form.Item
                name="packageSchemes"
                label="Package Schemes"
                rules={[
                  {
                    required: isVerifying,
                    message: "Please select a package scheme!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a package scheme"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  options={secondDropdownOptions.map((scheme) => ({
                    value: scheme.id,
                    label: scheme.admin_display_name,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      )}

      <Table
        dataSource={tableData}
        columns={columns}
        pagination={false}
        bordered
        // scroll={{
        //   y: 440,
        //   x: "100%",
        // }}
        scroll={{
          y: 440,
          x: "max-content", // Allows horizontal scrolling based on total column width
        }}
      />
      <Form.Item>
        <div style={{ display: "flex", gap: "15px", marginTop: "15px" }}>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            htmlType="button"
            loading={saving}
            disabled={tableData.length === 0}
            onClick={handleSaving}
          >
            {saving ? <Spin size="small" /> : "Save"}
          </Button>

          {isAGTenant && (
            <Button
              type="primary"
              icon={<SaveOutlined />}
              htmlType="button"
              loading={verifying}
              disabled={tableData.length === 0}
              onClick={handleVerifyClick}
            >
              {verifying ? (
                <Spin size="small" />
              ) : (
                `Send to ${localStorage.getItem("tenant_slug")} `
              )}
            </Button>
          )}

          <Dropdown overlay={downloadMenu(1)} trigger={["click"]}>
            <Button type="primary" icon={<DownOutlined />} htmlType="button">
              {saving ? <Spin size="small" /> : "Download"}
            </Button>
          </Dropdown>
        </div>
      </Form.Item>
    </Form>
  );
};

export default OutputForm;
