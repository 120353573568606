import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Upload,
  Progress,
  message,
  Image,
  Select,
} from "antd";
import {
  ProjectOutlined,
  FileTextOutlined,
  PlusOutlined,
  UploadOutlined,
  TagsOutlined,
  ProfileOutlined,
  TableOutlined,
  FileDoneOutlined,
  BookOutlined,
} from "@ant-design/icons";
import { editJobWithImages } from "../utils/jobApi"; // Import the API function

const { Option } = Select;

const jobOptions = [
  { value: "table", label: "Table", icon: <TableOutlined /> },
  { value: "receipt", label: "Receipt", icon: <FileDoneOutlined /> },
  { value: "book_notes", label: "Book/Notes", icon: <BookOutlined /> },
  {
    value: "omr",
    label: "OMR",
    icon: <ProfileOutlined />,
    subOptions: [
      { value: "omr/50", label: "OMR/50", icon: <ProfileOutlined /> },
      { value: "omr/100", label: "OMR/100", icon: <ProfileOutlined /> },
      { value: "omr/200", label: "OMR/200", icon: <ProfileOutlined /> },
      // { value: "omr/300", label: "OMR/300", icon: <ProfileOutlined /> },
    ],
  },
];

const BASE_URL = "https://aroma.nextai.asia/api/";

const EditJobModal = ({
  projectId,
  isVisible,
  onCancel,
  onFinish,
  loading,
  editJobId,
  setIsEditJobModalVisible,
  populateJobDetails,
}) => {
  const [fileList, setFileList] = useState([]);
  const [overallProgress, setOverallProgress] = useState(0);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const [type, setType] = useState("");
  const [omrSubType, setOmrSubType] = useState("");
  const [columnsOptions, setColumnsOptions] = useState([]);

  useEffect(() => {
    if (populateJobDetails) {
      const { type, columns, description, name, image_paths } = populateJobDetails;
      const isOmrSubType = type.startsWith("omr/");

      // Map `image_paths` to `fileList` format
      const mappedFiles = image_paths?.map((path, index) => ({
        uid: `${index}`, // Unique ID
        name: path.split("/").pop(), // File name
        status: "done", // Mark as uploaded
        url: `${BASE_URL}${path}`, // Full URL
        // url :`${BASE_URL}`
      }));

      setFileList(mappedFiles || []); // Set the mapped files to `fileList`

      form.setFieldsValue({
        name,
        description,
        columns,
        type: isOmrSubType ? "omr" : type,
        omr_sub_type: isOmrSubType ? type : "",
      });

      setType(isOmrSubType ? "omr" : type);
      setOmrSubType(isOmrSubType ? type : "");
      setColumnsOptions(columns || []);
    }
  }, [populateJobDetails, form]);

  useEffect(() => {
    if (["omr/50", "omr/100", "omr/200",].includes(omrSubType)) {
      setColumnsOptions(["first_name", "middle_name", "last_name"]);
      form.setFieldsValue({
        description: "Image contains bubble OMR sheet with first_name, middle_name and last_name.",
      });
    } else if (type === "table" || type === "receipt" || type === "book_notes") {
      setColumnsOptions(["sn", "name", "mobile_number"]);
      form.setFieldsValue({
        description: "Image contains table of names of students with their mobile number.",
      });
    } else {
      setColumnsOptions([]);
      form.setFieldsValue({
        description: "",
      });
    }
  }, [type, omrSubType, form]);

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList);
    setOverallProgress(0);
  };

  const handlePreview = async (file) => {
    setPreviewImage(file.thumbUrl || file.url);
    setPreviewVisible(true);
  };

  const handleSubmit = async (values) => {
    if (submitting) {
      return;
    }

    setSubmitting(true);
    setOverallProgress(0);
    // console.log("values comp->editjobmodal", values);
    const formData = new FormData();
    formData.append("name", values.name);

    const typeToSubmit = type === "omr" ? omrSubType : type;
    formData.append("type", typeToSubmit);

    formData.append("description", values.description);
    formData.append("columns", values.columns.join(","));

    const allFilePaths = [];

    fileList.forEach((file) => {
      console.log("file", file);
      if (file.url) {
        allFilePaths.push(file.url); // Existing file URL
      } else if (file.originFileObj) {
        // For new files, append to FormData
        formData.append("files", file.originFileObj);
      }
    });

    formData.append("all_files", JSON.stringify(allFilePaths));

    try {
      const response = await editJobWithImages(
        editJobId,
        formData,
        (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.round((loaded / total) * 100);
          setOverallProgress(percent);
        }
      );

      message.success("Job edited successfully!");
      form.resetFields();
      setFileList([]);
      setOverallProgress(0);

      onFinish(response.job);
      onCancel();
    } catch (error) {
      console.error("Error editing job:", error);
      message.error("Failed to edit job");
      setOverallProgress(0);
    } finally {
      setSubmitting(false);
      setIsEditJobModalVisible(false);
    }
  };

  return (
    <Modal
      title="Edit Job"
      visible={isVisible}
      onCancel={() => {
        onCancel();
        setOverallProgress(0);
        setFileList([]);
        form.resetFields();
      }}
      footer={null}
      width="80%"
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label={<span><ProjectOutlined /> Job Name</span>}
          name="name"
          rules={[{ required: true, message: "Please enter the job name" }]}
        >
          <Input placeholder="Enter job name" />
        </Form.Item>

        <Form.Item
          label={<span><ProfileOutlined /> Job Type</span>}
          name="type"
          rules={[{ required: true, message: "Please select the job type" }]}
        >
          <Select
            placeholder="Select job type"
            onChange={(value) => {
              setType(value);
              setOmrSubType("");
              form.setFieldsValue({ omr_sub_type: "" });
            }}
            value={type}
          >
            {jobOptions.map((option) =>
              option.subOptions ? (
                <Option key={option.value} value={option.value}>
                  {option.icon} {option.label}
                </Option>
              ) : (
                <Option key={option.value} value={option.value}>
                  {option.icon} {option.label}
                </Option>
              )
            )}
          </Select>
        </Form.Item>

        {type === "omr" && (
          <Form.Item
            label={<span><ProfileOutlined /> OMR Sub-Type</span>}
            name="omr_sub_type"
            rules={[{ required: true, message: "Please select the OMR sub-type" }]}
          >
            <Select
              placeholder="Select OMR sub-type"
              onChange={(value) => setOmrSubType(value)}
              value={omrSubType}
            >
              {jobOptions
                .find((option) => option.value === "omr")
                .subOptions.map((subOption) => (
                  <Option key={subOption.value} value={subOption.value}>
                    {subOption.icon} {subOption.label}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label={<span><FileTextOutlined /> Description</span>}
          name="description"
          rules={[{ required: true, message: "Please enter the job description" }]}
        >
          <Input.TextArea placeholder="Enter job description" />
        </Form.Item>

        <Form.Item
          label={<span><TagsOutlined /> Attributes/Columns</span>}
          name="columns"
          rules={[{ required: true, message: "Please specify the columns (tags)" }]}
        >
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Enter column names for the batch (e.g., Column1, Column2)"
            tokenSeparators={[","]}
          >
            {columnsOptions.map((col) => (
              <Option key={col} value={col}>
                {col}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={<span><UploadOutlined /> Upload Images</span>}
        >
          <Upload
            listType="picture-card"
            fileList={fileList}
            onChange={handleFileChange}
            onPreview={handlePreview}
            beforeUpload={() => false}
            multiple
          >
            {fileList.length < 50 && (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Select Images</div>
              </div>
            )}
          </Upload>
        </Form.Item>

        {fileList.length > 0 && (
          <div style={{ marginBottom: "16px" }}>
            <Progress
              percent={overallProgress}
              status={overallProgress === 100 ? "success" : "active"}
            />
          </div>
        )}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading || submitting}
            icon={<PlusOutlined />}
          >
            Edit Job
          </Button>
        </Form.Item>
      </Form>

      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <Image alt="Preview" src={previewImage} style={{ width: "100%" }} />
      </Modal>
    </Modal>
  );
};

export default EditJobModal;
