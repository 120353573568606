import { useState } from "react";

const useImageNavigation = (
  jobDetails,
  form,
  tableData,
  setFormFields
) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Save the current output for the current image (no change to jobDetails)
  const saveCurrentOutput = () => {
    if (!jobDetails || !jobDetails.columns) return;

    const currentOutputObj = jobDetails.job_output[currentImageIndex];
    const outputDataKeys = Object.keys(currentOutputObj);
    const outputDataKey = outputDataKeys.length > 0 ? outputDataKeys[0] : null;

    if (!outputDataKey) return;

    const updatedOutput = form.getFieldsValue();
    const {
      schoolName,
      scholarshipScheme,
      subject,
      district,
      principalName,
      packageSchemes,
      ...restOutput
    } = updatedOutput;

    const newOutput = {
      schoolName,
      scholarshipScheme,
      subject,
      district,
      principalName,
    };

    tableData.forEach((row) => {
      const rowKey = row.key;
      const rowData = updatedOutput[rowKey] || {};
      newOutput[rowKey] = rowData;
    });

    // Only saving the data for the current index without modifying jobDetails
    const updatedJobOutput = [...jobDetails.job_output];
    updatedJobOutput[currentImageIndex] = {
      ...currentOutputObj,
      data: newOutput,
    };

    // No change to jobDetails directly, only saving at the current index
  };

  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      saveCurrentOutput();
      const newIndex = currentImageIndex - 1;
      setCurrentImageIndex(newIndex);
      loadImageData(newIndex);
    }
  };

  const handleNextImage = () => {
    if (currentImageIndex < jobDetails.image_paths.length - 1) {
      saveCurrentOutput();
      const newIndex = currentImageIndex + 1;
      setCurrentImageIndex(newIndex);
      loadImageData(newIndex);
    }
  };

  const loadImageData = (index) => {
    const outputObj = jobDetails.job_output[index];
    const outputDataKeys = Object.keys(outputObj);
    const outputDataKey = outputDataKeys.length > 0 ? outputDataKeys[0] : null;
    if (outputDataKey) {
      const outputData = outputObj[outputDataKey] || {};
      setFormFields(outputData, jobDetails.columns);
    }
  };

  const jobData =
    jobDetails && jobDetails.job_output[currentImageIndex]
      ? jobDetails.job_output[currentImageIndex]
      : {};

  return {
    currentImageIndex,
    handlePrevImage,
    handleNextImage,
    saveCurrentOutput,
    jobDetails,
    jobData, // Expose jobData for the current index
  };
};

export default useImageNavigation;