import React, { useState, useEffect } from "react";
import {
  Card,
  Spin,
  Space,
  Select,
  Table,
  Input,
  Typography,
  DatePicker,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import useAssignJobsData from "../hooks/useAssignJobsData";

const { Option } = Select;
const { Search } = Input;
const { Text } = Typography;
const { RangePicker } = DatePicker;

const SelectJobs = ({
  projectsData,
  selectedProjects,
  handleProjectSelect,
  verificationFilter,
  handleVerificationFilterChange,
  handleJobSearchChange,
  filteredJobs,
  handleJobRowClick,
  rowClassName,
  jobColumns,
}) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [filteredJobsState, setFilteredJobsState] = useState(filteredJobs);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { loading: filteredJobsLoading } = useAssignJobsData();

  // Filter jobs based on the selected date range
  const filterJobsByDate = (jobs, range) => {
    if (!range || !range[0] || !range[1]) return jobs; // If no date range is selected, return all jobs
    const startDate = new Date(range[0]);
    const endDate = new Date(range[1]);

    return jobs.filter((job) => {
      const executedAtDate = new Date(job.created_at);
      return executedAtDate >= startDate && executedAtDate <= endDate;
    });
  };

  useEffect(() => {
    const validDateRange = Array.isArray(dateRange) ? dateRange : [null, null];
    const jobsFilteredByDate = validDateRange.every((date) => date === null)
      ? filteredJobs // No date range selected, display all jobs
      : filterJobsByDate(filteredJobs, validDateRange); // Apply date filtering

    setFilteredJobsState(jobsFilteredByDate);
  }, [filteredJobs, dateRange]);

  // Handle DateRangePicker change
  const handleDateRangeChange = (dates) => {
    setDateRange(dates || [null, null]);
    setCurrentPage(1); // Reset to the first page when filtering
  };

  // Handle pagination changes
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  return (
    <Card
      title={
        <Space
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text strong>Select Jobs</Text>
          <Space>
            {/* Verification Status Filter */}
            <Select
              value={verificationFilter}
              onChange={handleVerificationFilterChange}
              style={{ width: 150 }}
              options={[
                { label: "All", value: "all" },
                { label: "Verified", value: "verified" },
                { label: "Unverified", value: "unverified" },
              ]}
            />

            {/* Instant Search Input */}
            <Search
              placeholder="Search by Job or Agent"
              allowClear
              onChange={handleJobSearchChange}
              style={{ width: 200 }}
              prefix={<SearchOutlined />}
            />
          </Space>
        </Space>
      }
      bordered={true}
      style={{ height: "100%" }}
    >
      {/* Project Multiselect */}
      <Space
        direction="vertical"
        style={{ width: "100%", marginBottom: "16px" }}
      >
        <Select
          mode="multiple"
          allowClear
          placeholder="Filter by Projects"
          onChange={handleProjectSelect}
          style={{ width: "100%" }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          notFoundContent="No projects found"
          maxTagCount="responsive"
        >
          {projectsData.map((project) => (
            <Option key={project.id} value={project.id}>
              {project.name}
            </Option>
          ))}
        </Select>
      </Space>

      {/* Date Range Picker below search box */}
      <RangePicker
        placeholder={["Job Execution Start Date", "Job Execution End Date"]}
        onChange={handleDateRangeChange}
        style={{ width: "100%", marginBottom: "16px" }}
        format="YYYY-MM-DD"
      />

      {/* Jobs Table */}
      <Spin spinning={filteredJobsLoading}>
        <Table
          dataSource={filteredJobsState} // Display filtered jobs
          columns={jobColumns}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: filteredJobsState.length,
            showSizeChanger: true,
          }}
          rowKey="id"
          size="middle"
          scroll={{ y: 300 }}
          onRow={(record) => ({
            onClick: () => handleJobRowClick(record.id),
          })}
          onChange={handleTableChange}
          rowClassName={rowClassName}
        />
      </Spin>
    </Card>
  );
};

export default SelectJobs;
