// src/components/Navbar.js
import React, { useState } from "react";
import { Menu, Modal, Tag, Button, Dropdown } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import {
  DashboardOutlined,
  LoginOutlined,
  ProjectOutlined,
  AuditOutlined,
  PoweroffOutlined,
  ExclamationCircleOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  BarsOutlined,
} from "@ant-design/icons";
import { logoutUser, getUserRole } from "../utils/auth"; // Import logout and role helper
import ChangePasswordModal from "./ChangePasswordModal";

const Navbar = ({ isAuthenticated, setIsAuthenticated, setRole }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = React.useState(location.pathname);
  const role = getUserRole(); // Get the current role to conditionally show items

  // Modal -> Change Password
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);

  const tenantName = localStorage.getItem("tenant_name");

  const { confirm } = Modal;

  const LogoutMenuItem = () => {
    const handleLogout = () => {
      confirm({
        title: "Confirm Logout",
        icon: <PoweroffOutlined />, // Optional: Add an icon to the modal
        content: "Are you sure want to logout?",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          console.log("Logout confirmed.");
          logoutUser(); // Clear the session
          setIsAuthenticated(false); // Update the authentication state
          setRole(null); // Clear the role
          navigate(`/${localStorage.getItem("tenant_slug")}/login`); // Redirect to login
        },
        onCancel() {
          console.log("Logout canceled.");
        },
      });
    };

    return (
      <Menu.Item
        key="logout" // Changed from '/logout' to 'logout'
        icon={
          <PoweroffOutlined style={{ fontSize: "27px", fontWeight: "900" }} />
        }
        style={{
          color: "red",
          paddingTop: "15px",
          paddingRight: "5px",
        }}
        onClick={handleLogout}
      ></Menu.Item>
    );
  };
  const addMenu = (
    <Menu>
      {role === "admin" && "supervisor" && (
        <Menu.Item
          key="/failed-jobs"
          icon={<ExclamationCircleOutlined />}
          onClick={() => navigate("/failed-jobs")}
        >
          Activity Logs
        </Menu.Item>
      )}
      {role === "admin" && (
        <Menu.Item
          key="/manage-subordinates"
          icon={<UsergroupAddOutlined />}
          onClick={() => navigate("/manage-subordinates")}
        >
          Manage Subordinates
        </Menu.Item>
      )}
      <Menu.Item
        icon={<SettingOutlined />}
        onClick={() => setChangePasswordModalOpen(true)}
      >
        Change Password
      </Menu.Item>
    </Menu>
  );

  const handleMenuClick = (e) => {
    if (e.key !== "logout") {
      // Exclude 'logout' from being handled here
      setCurrent(e.key);
      navigate(e.key); // Navigate to the selected page
    }
    // No action needed for 'logout' as it's handled by LogoutMenuItem
  };

  return (
    <Menu
      mode="horizontal"
      selectedKeys={[current]}
      onClick={handleMenuClick}
      style={{
        fontWeight: "600",
      }}
    >
      {/* Logo Image */}
      <Menu.Item
        key="logo"
        disabled
        style={{
          cursor: "default",
        }}
      >
        <img
          src="/aroma-logo.png"
          alt="Next AI"
          style={{
            height: "35px",
            marginRight: "12px",
            marginTop: "7px",
            marginBottom: "0px",
          }}
        />
        <span
          style={{
            color: "black",
            marginTop: "5px",
            fontSize: "22px",
            fontStyle: "bold",
            fontWeight: "400",
            letterSpacing: "2px",
          }}
        >
          Aroma
          {tenantName && (
            <Tag
              color="blue"
              style={{
                fontSize: "12px",
                padding: "0px 7px",
                marginRight: "0px",
                verticalAlign: "super",
              }}
            >
              {tenantName}
            </Tag>
          )}
        </span>
      </Menu.Item>
      {/* Left-aligned menu items */}
      <Menu.Item
        style={{ paddingTop: "7px" }}
        key="/dashboard"
        icon={<DashboardOutlined />}
      >
        Dashboard
      </Menu.Item>
      {/* Show Project Management menu only for admin and supervisor */}
      {(role === "admin" || role === "supervisor") && (
        <>
          <Menu.Item
            style={{ paddingTop: "7px" }}
            key="/project-management"
            icon={<ProjectOutlined />}
          >
            Projects
          </Menu.Item>
          <Menu.Item
            style={{ paddingTop: "7px" }}
            key="/assign-jobs"
            icon={<AuditOutlined />}
          >
            Assign Jobs
          </Menu.Item>
        </>
      )}

      {role === "root" && (
        <Menu.Item
          key="/users-manegement"
          style={{ paddingTop: "7px" }}
          icon={<LoginOutlined />}
        >
          Manage Users
        </Menu.Item>
      )}

      <Menu.Item
        disabled
        style={{
          marginLeft: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "default",
        }}
      >
        <Dropdown overlay={addMenu} trigger={["hover"]}>
          <Button
            type="text"
            icon={<BarsOutlined style={{ fontSize: "27px" }} />}
          />
        </Dropdown>
      </Menu.Item>

      {/* Right-aligned Login/Logout button */}
      {isAuthenticated ? (
        <>
          <LogoutMenuItem />
        </>
      ) : (
        <Menu.Item key="/login" icon={<LoginOutlined />}>
          Login
        </Menu.Item>
      )}
      {/* changePasswordModalOpen */}
      <ChangePasswordModal
        changePasswordModalOpen={changePasswordModalOpen}
        setChangePasswordModalOpen={setChangePasswordModalOpen}
      />
    </Menu>
  );
};

export default Navbar;
